import axios from 'axios';
import Auth from '../services/FirebaseAuth';
import config from '../config';
import { getCoach } from './coach';
import {
  notifyAPIError,
  notifyHandledError,
} from '../services/ErrorMonitoring';
import { generateExternalUrlQueryPath } from '../utils';

async function fetchEventsList(data) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/events/list`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      data,
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching events list' });
    return null;
  }
}

async function fetchEventUserCount({ eventId }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const data = { eventId };
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/events/users/count`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      data,
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching events list' });
    return null;
  }
}

async function getEventById(eventId) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'GET',
      url: `${config.api.auraServices}/events/${eventId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error fetching event', eventId });
    return null;
  }
}

async function createEvent(data) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/events`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      data,
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while creating events' });
    return null;
  }
}

async function editEvent({ eventId, data }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'PUT',
      url: `${config.api.auraServices}/events/${eventId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      data,
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, { message: 'Error while editing event' });
    return null;
  }
}

function getEventPublicUrl(
  coach,
  event,
  { referralCode, referralType = 'coach_payout_event' } = {}
) {
  if (!event || !coach) return null;
  const { slug } = event;
  const { slug: coachSlug } = coach;
  if (!slug || !coachSlug) return null;
  const path = `${config.userWeb}/coaches/${coachSlug}/events/${slug}`;
  const query = {};
  if (referralCode) {
    query.referralCode = referralCode;
    query.referralType = referralType;
  }
  return generateExternalUrlQueryPath(path, query);
}

async function addEventToCommunity({ eventId, communityId, data }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'PUT',
      url: `${config.api.auraServices}/events/${eventId}/${communityId}/attach`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      data,
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while adding event to community',
    });
    return null;
  }
}

async function removeEventFromCommunity({ eventId }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'DELETE',
      url: `${config.api.auraServices}/events/${eventId}/community`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while deleting event from community',
    });
    return null;
  }
}

async function removeCoachFromEvent({ eventCoachId }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'DELETE',
      url: `${config.api.auraServices}/events/coaches/${eventCoachId}`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while adding coach to event',
    });
    return null;
  }
}
async function addCoachToEvent({ data }) {
  const authToken = await Auth.getUserAuthToken();

  try {
    const options = {
      method: 'POST',
      url: `${config.api.auraServices}/events/coaches`,
      headers: {
        Authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      data,
      json: true,
    };
    const response = await axios(options);
    if (response && response.status === 200 && response.data) {
      return response.data;
    }
    return null;
  } catch (error) {
    notifyAPIError(error, {
      message: 'Error while removing coach from event',
    });
    return null;
  }
}

const getInitialCoaches = async (coaches = []) => {
  const coachList = [];
  try {
    if (coaches?.length === 0) return [];
    const filteredCoach = coaches.filter((item) => item?.role !== 'owner');

    const coachPromises = filteredCoach.map(async (coach) => {
      const coachData = await getCoach(coach.coachId);

      if (coachData) {
        coachList.push(coachData);
      }
    });
    await Promise.all(coachPromises);
    return coachList;
  } catch (error) {
    notifyHandledError(error, { message: 'Unable to get event coach details' });
  }
  return coachList;
};

export {
  fetchEventsList,
  fetchEventUserCount,
  getEventById,
  createEvent,
  editEvent,
  getEventPublicUrl,
  addCoachToEvent,
  addEventToCommunity,
  removeEventFromCommunity,
  getInitialCoaches,
  removeCoachFromEvent,
};

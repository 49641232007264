const contentConstants = Object.freeze({
  DURATION_KEYS: {
    SHORT: 0,
    MEDIUM: 1,
    LONG: 2,
    LONGEST: 3,
    ALL: 4,
  },

  DURATION_KEYS_FOR_REQUEST: {
    0: `0-5`,
    1: `5-10`,
    2: `10-20`,
    3: `20+`,
    4: `all`,
  },

  DURATION_DISPLAY_NAMES: {
    0: `3 min`,
    1: `7 min`,
    2: `10 - 20`,
    3: `20+ min`,
    4: `All`,
  },

  CONTENT_CATEGORY: {
    RECOMMEND: 'recommend',
    NEW: 'new',
    POPULAR: 'popular',
    SERIES: 'series',
    TOP: 'top',
    ALL: 'all',
  },

  UPLOAD_TYPES: {
    AFFIRMATIONS: 'affirmations',
    ENERGY_HEALING: 'energyHealing',
    ASMR: 'asmr',
    BREATHWORK: 'breathwork',
    COURSE: 'course',
    EMOTIONAL_INTELLIGENCE: 'emotionalIntelligence',
    HEALTH_COACHING: 'healthCoaching',
    HYPNOSIS: 'hypnosis',
    LIFE_COACHING: 'lifeCoaching',
    MINDFULNESS: 'mindfulness',
    THERAPY: 'therapy',
    MUSIC: 'music',
    SPIRITUALITY: 'spirituality',
    SERIES: 'series',
    SOUNDSCAPE: 'soundscape',
    STORY: 'story',
    WORK_WELLNESS: 'workWellness',
  },

  UPLOAD_CONFIG_TYPE: {
    TYPES: 'types',
    TAGS: 'tags',
    TOPICS: 'topics',
    COURSE_COMMUNITY_EVENT_TOPICS: 'courseCommunityEventTopics',
  },

  UPLOAD_TYPE_PERSONAL_STORY: 'personalStory',

  LIVE_CONTENT_TYPES: {
    MINDFULNESS: 'mindfulness',
    LIFE_COACHING: 'lifeCoaching',
    HYPNOSIS: 'hypnosis',
  },

  CHANNEL_TYPES: {
    STORY: 'story',
    MINDFULNESS: 'mindfulness',
    LIFE_COACHING: 'lifeCoaching',
    THERAPY: 'therapy',
  },

  CONTENT_UI_TYPES: {
    TRACKS: 'tracks',
    TOPICS: 'topics',
    CHANNELS: 'channels',
    EXPLORE_AURA: 'exploreAura',
    CONTENT_TYPE: 'contentType',
  },

  MIN_TOPICS_BY_TYPE: {
    personalStory: 0,
    default: 1,
  },

  MAX_TOPICS_BY_TYPE: {
    emotionalIntelligence: 1,
    hypnosis: 2,
    spirituality: 1,
    story: 1,
    therapy: 1,
    music: 2,
    workWellness: 2,
    healthCoaching: 2,
    energyHealing: 1,
    default: 3,
  },

  MAX_TAGS_BY_TYPE: {
    therapy: 1,
    spirituality: 1,
    default: 2,
  },

  TRACK_TITLE_MIN_LENGTH: 2,
  TRACK_TITLE_MAX_LENGTH: 40,
  TRACK_DESCRIPTION_MIN_LENGTH: 40,
  TRACK_DESCRIPTION_MAX_LENGTH: 750,
  SHORT_TRACK_MIN_DURATION: 170,
  COURSE_VIDEO_TRACK_MIN_DURATION: 30,
  LONG_TRACK_MIN_DURATION: 300,
  TRACK_EMOTIONS_MAX: 3,

  STATUSES: {
    created: 'created',
    waitingForReview: 'waitingForReview',
    waitingForAudioEdit: 'waitingForAudioEdit',
    waitingForApproval: 'waitingForApproval',
    rejected: 'rejected',
    approved: 'approved',
  },

  STATUSES_DISPLAY: {
    created: 'track_status_waiting_for_review',
    waitingForReview: 'track_status_waiting_for_review',
    waitingForAudioEdit: 'track_status_waiting_for_audio_edit',
    waitingForApproval: 'track_status_waiting_for_approval',
    rejected: 'track_status_rejected',
    approved: 'track_status_approved',
  },

  THUMBS_SIZES: {
    photo100Url: '100',
    photo200Url: '200',
    photo300x375Url: '300x375',
    photo400Url: '400',
    photo400x225Url: '400x225',
    photo400x500Url: '400x500',
    photo50Url: '50',
  },

  analyticsTypes: {
    SLEEP: 'sleep',
    MINDFULNESS: 'mindfulness',
    MOOD: 'mood',
  },

  NO_WELCOME_AUDIO_TRACK_TYPES: [
    'music',
    'podcast',
    'soundHealing',
    'soundscape',
  ],
});

export default contentConstants;

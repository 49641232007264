import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

function Input(
  {
    style,
    className,
    error,
    borderStyle,
    allowConsecutiveSpaces = false,
    fontType,
    ...props
  },
  ref
) {
  const inputRef = useRef();
  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus();
    },
    select: () => {
      inputRef.current.select();
    },
  }));
  return (
    <div
      className={
        borderStyle ? styles.inputContainerBorder : styles.inputContainer
      }
      style={style}>
      <input
        ref={inputRef}
        className={classnames(
          styles.customInput,
          { [styles.inputStyles]: fontType },
          { [styles.errorInput]: !!error },
          className
        )}
        onKeyDown={(event) => {
          if (
            event.code === 'Space' &&
            event.target.value.endsWith(' ') &&
            !allowConsecutiveSpaces
          )
            event.preventDefault();
        }}
        {...props}
      />
    </div>
  );
}

export default forwardRef(Input);
